export default {
  common: {
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
    by: 'By',
    from: 'From',
    to: 'To',
    created: 'Created',
    updated: 'Updated',
    channel: 'Channel',
    previous: 'Previous',
    resolved: 'Resolved',
    errors: {
      addissue: 'There was a problem adding the issue, please try again.',
    },
    placeholder: {
      date: 'Pick a date',
    },
  },

  filedrop: {
    header: 'DRAG AND DROP',
    hint: 'Drop file here or',
    label: 'browse your files.',
    hover: 'Drop to upload file',
    button: 'Remove file',
  },

  sidebar: {
    links: {
      operatingInfo: 'Operating info',
      newIssue: 'New disturbance',
    },
  },

  topbar: {
    dropdown: {
      profile: 'My account',
      logout: 'Log out',
    },
  },

  profile: {
    heading: 'Profile',
    accountDetails: 'Account details',
    settings: {
      heading: 'Settings',
      language: 'Language',
    },
    labels: {
      name: 'Name',
      email: 'Email',
      currentLanguage: 'Current language',
    },
    buttons: {
      sendPasswordResetEmail: 'Reset password',
      sendPasswordResetEmailClicked: 'Reset password email sent',
    },
  },

  cookieBanner: {
    text: 'We use cookies to improve your user experience. Do you agree to the usage of cookies for this purpose?',
  },

  operatingInfo: {
    common: {
      appPrivate: 'App - Private',
      appBusiness: 'App - Business',
      appAll: 'App - All',
      apiExternal: 'API - Merchants',
      webExternal: 'Web - Swish.nu',
      webBusiness: 'Web - Swish Business Web',
      webAll: 'Web - All',
      all: 'All',
      newDisturbance: 'New disturbance',
      publish: 'Publish',
      published: 'Published',
      publishChanges: 'Publish changes',
      toBePublishedIn: 'Publish in',
      toBePublished: 'Publish',
      publishedIn: 'Published in',
      publishIn: 'Publish in',
      disturbance: 'Disturbance',
      DISTURBANCE: 'Disturbance',
      downtime: 'Downtime',
      DOWNTIME: 'Downtime',
      planned: 'Planned',
      active: 'Ongoing',
      disturbanceType: 'Type',
      message: 'Message',
      title: 'Title',
      edit: 'Edit',
      preview: 'Preview',
    },

    validation: {
      noChannelSelected: 'You need to choose at least one channel',
      noEmptyFields: 'Enter in field',
    },

    login: {
      heading: 'Admin Portal',
      labels: {
        email: 'Email',
        password: 'Password',
      },
      placeholders: {
        email: 'Input email',
        password: 'Input password',
      },
      buttons: {
        submit: 'Log in',
        lostPassword: 'Forgot your password?',
        back: 'Back',
        sendPasswordResetEmail: 'Send reset password email',
        sendPasswordResetEmailClicked: 'Reset password email sent',
      },
      firebaseErrors: {
        'auth/invalid-email': 'Email is in wrong format.',
        'auth/wrong-password': 'Wrong password.',
        'auth/user-not-found': 'Couldn´t find the user, Check input email.',
      },
    },

    create: {
      heading: 'New disturbance',
      issueType: 'Type',
      issueProvider: 'Provider',
      issueBank: 'Which bank?',
      issueService: 'Affected services',
      status: 'Status',
      channels: 'Send message through',
      swish: 'Swish',
      bank: 'Bank',
      unknown: 'Unknown',
      supplier: 'Supplier',
      bankId: 'BankID',
      bankgirot: 'Bankgirot',
      riksbanken: 'Riksbanken',
      merchants: 'Merchants',
      app: 'App',
      private: 'Private',
      companies: 'Company',
      all: 'All',
      active: 'Ongoing',
      planned: 'Planned',
      dialogConfirm: 'Confirm publication',
      placeholderBank: 'Select bank',
      externalWarning:
        ' Note that selecting API - Merchants will notify them that Swish cannot be used as a payment option',

      dialog: {
        leaveTitle: 'Warning',
        leaveMessage: 'Your changes have not been published, are you sure that you want to leave',
        templateChangeHeader: 'Warning',
        templateChangeMessage:
          'You have unsaved changes that will be lost if you proceed with changing the template',
        templateChangeConfirm: 'Change',
      },

      buttons: {
        leave: 'Leave',
        cancel: 'Cancel',
      },
    },
    list: {
      heading: 'Operating info',
      ongoing: 'Published',
      upcoming: 'Upcoming',
      resolved: 'Resolved',
      noOngoing: 'No ongoing issues',
      noPlanned: 'No upcoming issues',
      noResolved: 'No resolved issues',
      buttons: {
        newIssue: 'New disturbance',
      },
    },
    issueModal: {
      common: {
        copy: 'copy',
        delete: 'delete',
        resolve: 'resolve',
        update: 'update',
        ctaResolve: 'Do you want to resolve the disturbance?',
        ctaResolveDescription:
          'The message will be deleted from app/website. If the disturbance is published on the website it will show as "Resolved" in "History".',
        ctaCopy: 'Do you want to copy the disturbance?',
        ctaCopyDescription:
          'You will begin  the creation of a new disturbance with same contents at this one',
        ctaDelete: 'Do you want to delete the disturbance?',
        ctaDeleteDescription: 'The message will be deleted from app/website.',
        ctaUpdate: 'Do you want to update the disturbance?',
        ctaUpdateDescription: 'The message will be updated with your changes',
      },
      buttons: {
        resolveIssue: 'Resolve Issue',
        copy: 'Copy',
        delete: 'Delete',
      },
    },

    feedback: {
      title: 'User Feedback',
      description:
        'This page lists all the user feedback that has been received from developer.swish.nu.',
      page: 'Page',
      comments: 'Comments',
      votes: 'Votes',
      total: 'Score',
      upvotes: 'Upvotes',
      downvotes: 'Downvotes',
      type: 'Type',
      date: 'Date',
      category: 'Category',
      message: 'Message',
    },
  },

  company: {
    policy: 'Privacy policy',

    errors: {
      validationError:
        "The number you entered doesn't seem to be correct. Check the input field and try again.",
      userCancel: 'Log in was canceled.',
      noClient: 'No BankID client could be found.',
      timeout: 'Signing timed out, please try again.',
      personNumber:
        "The personal identity number provided isn't connected to Swish. Please contact your bank.",
      swishNumber: 'The Swish number is invalid. Please contact your bank.',
      notAuthorized: 'You are not authorised. Please contact your bank.',
      sign: 'A signing process is already in progress.',
      swishService: 'No Swish agreement could be found. Please contact your bank.',
      common:
        "Something went wrong, please try again. If the problem persists, please contact support support{'@'}getswish.se",
    },

    login: {
      safariWarning:
        'The default cookie settings in Safari prevents us from logging you in. We recommend you use an alternative browser or change your privacy settings to allow third party cookies',
      heading: 'Company Portal',
      personalNumber: 'Personal identity number',
      personalNumberPlaceholder: 'yyyymmdd-xxxx',
      organizationNumber: 'Corporate identity number',
      organizationNumberPlaceholder: '10 or 12 digits',
      nonSwedishCheckbox: 'Foreign corporate identity number',
      swishNumber: 'Swish number',
      submit: 'Log in with BankID',
      collect: 'Open the BankID app on your device and scan the QR code',
      bankIdOnCard: 'BankID on card/file',
      mobileBankId: 'Mobile bankID',
      qrAlt: 'QR-kod för inloggning med Mobilt BankID',
    },

    profile: {
      heading: 'Certificate handling',
      subheading: 'Contact your bank to change account information.',
      update: 'Update',
    },

    error: {
      heading: 'Something went wrong',
    },

    sessionExpired: {
      heading: 'You’ve been logged out',
      text: 'Your session has expired and you’ve been logged out. Log in again to continue.',
    },

    certificates: {
      certificates: 'Certificates',
      new: 'New certificate',
      choosePlatform: 'Choose platform',
      currentStep: 'Step {current} of {total}',

      issued: 'Created',
      validThrough: 'Valid through',
      issuer: 'Issuer',
      requestedBy: 'Created by',
      status: 'Status',
      download: 'Download',
      revoke: 'Revoke',
      view: 'View',
      revoked: 'Revoked',
      valid: 'Valid',

      revokeModal: {
        heading: 'Do you want to revoke the certificate?',
        text: 'To revoke a certificate leads to interruption in its connected Swish solution and can’t be undone.',
        confirmButton: 'Yes, revoke',
        cancelButton: 'Close',
        confirmHeading: 'The certificate has been revoked',
        confirmText: 'You will find revoked certificates at the end of the list.',
      },

      viewModal: {
        heading: 'Your certificate',
        text: 'Download your certificate as a file or copy the text directly from the text box.',
        cancelButton: 'Close',
      },

      supportEmail: {
        subject: 'Swish Technical Support - Certificate',
        body: 'Describe your problem and we will help you.',
      },

      exportGuide: {
        done: 'Done',
        tabs: {
          create: 'New certificate',
          heading: 'Export certificate',
          import: 'Import',
          export: 'Export',
          validate: 'Validate',
          distribute: 'Distribute',
        },
        import: {
          button: 'I have imported the certificate',
        },
        export: {
          heading: 'Export certificate',
          button: 'I have exported the certificate',
        },
        validate: {
          heading: 'Validate certificate',
        },
      },

      createGuide: {
        tabs: {
          heading: 'Create a new certificate',
          request: 'Request',
          generate: 'Generate',
          download: 'Download',
          export: 'Export',
        },
        request: {
          button: 'I have created a CSR file',
          skip: 'Skip',
        },
        generate: {
          heading: 'Upload your CSR file',
          textUpload: 'Go to the location where you saved your CSR and drop the file here.',
          textPaste: 'Or paste from your CSR-file',
          placeholder: 'Typically starts with "-----BEGIN CERTIFICATE REQUEST-----"',
          button: 'Generate certificate',
          validationError:
            'CSR file couldn’t be found. Try to upload the file again or paste the text directly into the text field.',
        },
        download: {
          heading: 'Your certificate is ready',
          buttonDownload: 'Download certificate',
          done: 'Done',
          buttonSkipGuide: 'Skip Export guide',
          empty:
            'Something went wrong, we cannot find a certificate. Go back to previous step or the beginning of the guide and try again.',
        },
      },
    },
  },

  language: {
    toggle: 'Switch language to {language}',
    languages: {
      en: 'English',
      sv: 'Swedish',
    },
  },
};
