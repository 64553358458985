<script>
export default {
  name: 'CookieBanner',

  methods: {
    onClick(isConsenting) {
      const expireDate = this.getExpireDate();
      document.cookie = `cookie_consent_status=dismiss;expires=${expireDate}`;

      if (isConsenting) {
        document.cookie = `opt-in=true;expires=${expireDate}`;
      }

      this.$emit('onDismiss');
    },

    getExpireDate() {
      const expireDate = new Date();
      return new Date(expireDate.setFullYear(expireDate.getFullYear() + 1)).toUTCString();
    },
  },
};
</script>

<template>
  <div class="cookie-banner">
    {{ $t('cookieBanner.text') }}

    <div class="buttons">
      <button @click="onClick(false)" class="button transparent">{{ $t('common.no') }}</button>
      <button @click="onClick(true)" class="button">{{ $t('common.yes') }}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cookie-banner {
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  background: $button-color-secondary;
  padding: $space-base;

  @include tablet-large {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .buttons {
    @include button;
    display: flex;
    align-items: center;
    margin-top: $space-base;

    @include tablet-large {
      justify-content: center;
      margin-top: 0;
    }
  }

  .button {
    @include button;

    &.transparent {
      color: $interaction-color-primary;

      &:hover {
        color: darken($interaction-color-primary, 10%);
      }
    }
  }
}
</style>
