import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    redirect: '/company/certificates',
  },
  {
    path: '/company',
    component: () => import('@/_company/CompanyApp.vue'),
    children: [
      {
        path: '',
        redirect: 'certificates',
      },
      {
        path: 'login',
        name: 'company-login',
        meta: { fullscreen: true },
        component: () => import('@/_company/views/CompanyLogin.vue'),
      },
      {
        path: 'profile',
        name: 'company-profile',
        meta: { 'white-background': true },
        component: () => import('@/_company/views/ProfileView.vue'),
      },
      {
        path: 'certificates',
        component: () => import('@/_company/views/certificates/CertificatesView.vue'),
        children: [
          {
            path: '',
            name: 'certificates-list',
            component: () => import('@/_company/views/certificates/views/ListCertificates.vue'),
          },
          {
            path: 'create',
            name: 'certificates-create',
            meta: { fullscreen: true },
            component: () =>
              import('@/_company/views/certificates/views/createGuide/CreateGuide.vue'),
          },
          {
            path: 'export',
            name: 'certificates-export',
            meta: { fullscreen: true },
            component: () => import('@/_company/views/certificates/views/ExportGuide.vue'),
          },
        ],
      },
      {
        path: ':pathMatch(.*)*',
        component: () => import('@/views/PageNotFound.vue'),
      },
    ],
  },
  {
    path: '/operating-info',
    component: () => import('@/_internal/views/operatingInfo/OperatingInfo.vue'),
    children: [
      {
        path: '',
        name: 'operating-info',
        component: () => import('@/_internal/views/operatingInfo/views/list/OperatingInfoList.vue'),
      },
      {
        path: 'create',
        name: 'operating-create',
        meta: { 'white-background': true },
        component: () => import('@/_internal/views/operatingInfo/views/create/CreateIssue.vue'),
      },
      {
        path: 'duplicate/:id',
        name: 'operating-copy',
        props: true,
        component: () => import('@/_internal/views/operatingInfo/views/create/CreateIssue.vue'),
      },
      {
        path: 'profile',
        name: 'operating-profile',
        component: () => import('@/_internal/views/ProfileView.vue'),
      },
      {
        path: 'feedback',
        name: 'feedback',
        component: () => import('@/_internal/views/feedback/FeedbackView.vue'),
      },
    ],
  },
  {
    path: '/404',
    name: 'page-not-found',
    component: () => import('@/views/PageNotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _, next) => {
  if (to.matched.length) {
    next();
  } else {
    next('/404');
  }
});

export { router };
