<script>
import CookieBanner from './components/CookieBanner.vue';

export default {
  components: {
    CookieBanner,
  },

  data() {
    return {
      showCookieBanner: true,
    };
  },

  created() {
    const cookieBannerDismissed = document.cookie.includes('cookie_consent_status=dismiss');
    this.showCookieBanner = !cookieBannerDismissed;
  },
};
</script>

<template>
  <div id="app">
    <cookie-banner v-if="showCookieBanner" @onDismiss="showCookieBanner = false" />

    <router-view />
  </div>
</template>

<style lang="scss">
body {
  font-family: "SwishFont", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: $line-height;
}
</style>
