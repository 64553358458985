import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import operatingInfoApi from '@/_internal/helpers/operatingInfoApi';
import { firebaseApp } from '@/firebase';

export const checkIfLoggedIn = ({ commit, dispatch }) => {
  commit('setAuthenticationState', true);

  const auth = getAuth(firebaseApp);
  // This one will be listening on changes, for example login and logout.
  onAuthStateChanged(auth, (user) => {
    if (!user) {
      commit('setAuthenticationState', false);
      dispatch('clear');
      return;
    }

    auth.currentUser.getIdToken().then((token) => {
      commit('setToken', token);
      dispatch('login', user);
    });
  });
};

export const login = async ({ commit, state }, authData) => {
  if (!state.token) {
    return;
  }

  try {
    const me = await operatingInfoApi.me();
    commit('setUser', me);
    commit('setAuthInfo', authData);
    commit('setAuthenticationState', false);
  } catch (err) {
    console.error(err);
    commit('setAuthenticationState', false);
  }
};

export const logout = () => {
  const auth = getAuth(firebaseApp);
  signOut(auth).catch(console.error);
};

export const clear = ({ commit }) => {
  commit('setToken', '');
  commit('setUser', null);
  commit('setAuthInfo', null);
};
